<template>
  <div>
	<Header color="black"></Header>
    <div id="news-main">
      <div class="title-wrap">
        <div class="title">
          {{article.title}}
        </div>
        <div class="time">
          {{article.time}}
        </div>
      </div>
      <div class="content" v-html="article.content"></div>
	  <div class="paging">
	    <div class="left" @click="changeArticle(article.up_article)" v-if="article.up_article">
	      <i class="iconfont icon-back"></i>
	      <div class="pre">上一篇</div>
	    </div>
	    <div class="left" v-if="!article.up_article">
	      <div class="pre"></div>
	    </div>
	    <div class="iconfont icon-apps" @click="backList(article)"></div>
	    <div class="right" @click="changeArticle(article.next_article)" v-if="article.next_article">
	      <div class="next">下一篇</div>
	      <i class="iconfont icon-right"></i>
	    </div>
	    <div class="right" v-if="!article.next_article">
	      <div class="next"></div>
	    </div>
	  </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavHeader from '@/components/nav-header.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'
import Header from '@/components/header'
import { mapState } from 'vuex'

export default {
  name: "newsDetail",
  data () {
    return {
      article: {}
    }
  },
  components: {
    NavHeader,
    Footer,
	Header,
  },
  computed: {
    ...mapState(['newsApi']),
  },
  watch: {
    '$route': {
      handler: function (to) {
        if (to.name == "newsDetail" && to.params.id) {
          this.getData();
        }
      },
      immediate: true
    }
  },
  methods: {
    getData: function () {
      let that = this;

      that.$axios.post(that.newsApi + 'cms/articleDetail', {
        'article_id': that.$route.params.id
      })
        .then(res => {
          if (res.data.code == 1) {
            that.article = {
              ...res.data.data,
              time: utils.date("Y-m-d H:i:s", res.data.data.createtime)
            }
			document.title=that.article.title;
          }
        })
    },
	changeArticle:utils.debounce(function(e){
	  this.$router.push({ name: 'newsDetail' , params:{ id : e.id ,paramsName: this.$route.params.columnName}});
	},200,true),
	backList:utils.debounce(function(e){
	  this.$router.push({ name: "newsList", params: { paramsName: this.$route.params.columnName } });
	},200,true),
  },
}
</script>

<style lang="scss">
#news-main .content img{
  max-width: 100%;
  display: block;
  width: 100%;
  height: auto !important;
}
#news-main{
  width: 100%;
  padding: 0px 18px 44px;
  margin-top: 60px;
  box-sizing: border-box;
  .nav {
    padding: 43px 0 28px;
    .nav-wrap {
      display: inline-flex;
      box-sizing: border-box;
      align-items: center;
      font-size: 14px;
      padding: 10px 10px 10px 0;
    }
  }
  .title-wrap {
    .title {
      font-size: 19px;
      color: #000000;
      line-height: 30px;
    }
    .time {
      font-size: 13px;
      color: #999999;
      margin-top: 20px;
    }
  }
  .content {
    width: 100%;
    margin-top: 30px;
	
  }
  
  .paging {
    display: flex;
    margin-top: 66px;
    justify-content: center;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    height: 60px;
    align-items: center;
	.left,.right{
		display: flex;
		height: 60px;
		line-height: 60px;
		width: 100%;
		font-size: 14px;
	}
	.left{
		.pre {
		  width: 100%;
		  text-align: center;
		}
		.iconfont {
		  margin-right: auto;
		}
	} 
	.right{
		.next {
		  width: 100%;
		 text-align: center;
		}
		.iconfont {
		  margin-left: auto;
		}
	} 
	.icon-apps {
	  height: 60px;
	  line-height: 60px;
	  text-align: center;
	  font-size: 14px;
	  margin: 0 54px;
	color: #666666;
	}
  }
}
</style>