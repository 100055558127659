<template>
	<div>
		<div class="nav-warp">
			<div class="bar bar-nav " :class="{ 'show-bar-nav' : showBarNav ,'bg-nav' : bgNav}">
				<span class="iconwarp" @click="iconClick">
					<b class="icon" :class="color == 'white' ? 'white' : '' ">
						<em></em>
					</b>
				</span>
				<i class="iconfont icon-friend_light" :class="color == 'white' ? 'white' : '' " @click="memberClick" v-if="!showNav"></i>
			</div>
			<div class="nav" :class="{ 'show-nav' : showNav }">
				<div class="nav-list">
					<div class="nav-item" :class="[navIndex == showItemIndex ? 'show-item' : '']" v-for="(navItem,navIndex) in navList"
					  :key="navIndex">
						<div class="title">
							<span @click="navItemClick(navIndex)">{{navItem.name}}</span>
							<van-icon name="arrow-down" class="icon" v-if="navItem.children.length" @click="showNavItem(navIndex)"/>
						</div>
						<div class="nav-item-list">
							<div class="nav-itemitem" v-for="(navItemItem,navItemItemIndex) in navItem.children" @click="navItemItemClick(navItemItem)"
							 :key="navItemItemIndex">
								{{navItemItem.name}}
								<van-icon name="arrow" class="icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		inject: ['reload'], // 引入方法
		data: function() {
			return {
				bgNav: false,
				showBarNav: false,
				showNav: false,
				showItemIndex: -1,
				navList: [{
						name: "首页",
						children: [],
						pathName: "index"
					},
					{
						name: "小院生活",
						pathName: "lifeBrand",
						children: [{
								name: "品牌介绍",
								pathName: "lifeBrand"

							},
							{
								name: "业务介绍",
								pathName: "lifeProject"
							},
							{
								name: "发展历程",
								pathName: "lifeMilestone"
							},
							{
								name: "企业文化",
								pathName: "lifeCulture"
							},
							{
								name: "联系我们",
								pathName: "lifeContact"
							},
						],
					},
					{
						name: "新闻中心",
						children: [],
						pathName: "newsList",
						params:''
					},
					{
						name: "产品服务",
						pathName: "productLifehall",
						children: [{
								name: "小院生活馆",
								pathName: "productLifehall"
							},
							{
								name: "小院地产",
								pathName: "productDooryard"
							},
							{
								name: "小院家居",
								url: "http://mall.xiaoyuan.com.cn"
							},
						],
					},
					{
						name: "加入我们",
						children: [],
						url: "http://hr.xiaoyuan.com.cn",
					},
					{
						name: "投诉建议",
						pathName: "feedback",
						children: [],
					},

				],
				 channel: null, //所有分类集合
			}
		},
		props: {
			showBg: {
				type: Boolean,
				default: false
			},
			color: {
				type: String,
				default: "white"
			}
		},
		created: function() {
			if (this.showBg) {
				this.bgNav = true;
			}
		},
		computed: {
			...mapState(['newsApi', 'ocApi']),
		},
		mounted: function() {
			window.addEventListener('scroll', this.handleScroll);
			this.getNewsClass();
		},
		methods: {
			getNewsClass() {
				let that = this;
				that.$axios.get(that.ocApi + 'appmanage/dictionary', {
						params: {
							code: 'web_news'
						}
					})
					.then(res => {
						let data = res.data.data;
						let dataArr = [];
						for (const item of data) {
							dataArr.push(item.value);
						}
						that.channel = dataArr.join(',');
						
					
						that.navList[2].params = that.channel;
						let newArr = data.map(iterator => {
							return {
								name: iterator.name,
								pathName: 'newsList',
								params: iterator.value,
							}
						});
						let origin = [{
							name: '全部',
							pathName: 'newsList',
							params: dataArr.join(','),
						}]
						that.navList[2].children = origin.concat(newArr);
					})
			},
			iconClick: function() {
				this.showBarNav = !this.showBarNav;
				this.showNav = !this.showNav;
			},
			navItemClick: function(e) {
				var that = this;
				if (that.navList[e].children.length == 0) {
					if (that.navList[e].url) {
						window.location.href = that.navList[e].url
						this.showBarNav = false
						this.showNav = false
						return;
					}
					if (that.navList[e].pathName) {
						that.$router.push({
							name: that.navList[e].pathName
						})
						this.showBarNav = false
						this.showNav = false
						return;
					}
				} else {
					if (that.navList[e].pathName) {
						if (that.navList[e].pathName == that.$route.name) {
							// that.$router.go(0)
							this.reload()
						}
						that.$router.push({
							name: that.navList[e].pathName,
							params: {
								paramsName: that.navList[e].params
							}
						})
						this.showBarNav = false
						this.showNav = false
						return;
					}
				}
			},
			showNavItem: function(e) {
				if (this.showItemIndex == e) {
					this.showItemIndex = -1;
				} else {
					this.showItemIndex = e;
				}
			},
			// 二级菜单切换
			navItemItemClick: function(e) {
				if (e.pathName) {
					this.$router.push({
						name: e.pathName,
						params: {
							paramsName: e.params
						}
					});
					this.showBarNav = false;
					this.showNav = false;
				}
				if (e.url) {
					window.location.href = e.url;
					this.showBarNav = false;
					this.showNav = false;
					return;
				}
			},
			handleScroll: function() {
				if (!this.showBg) {
					let scrollY = window.scrollY;
					if (scrollY > 100) {
						this.bgNav = true
					} else {
						this.bgNav = false
					}
				}
			},
			memberClick: function() {
				this.$router.push({
					name: 'member'
				})
				this.showBarNav = false
				this.showNav = false
			}
		},
	}
</script>

<style lang="scss" scoped>
	/* header */

	.nav-warp {
		position: fixed;
		z-index: 1000;
		right: 0;
		left: 0;
		top: 0;
	}

	.bar {
		position: fixed;
		z-index: 10;
		right: 0;
		left: 0;
		top: 0;
		height: 44px;
		padding-right: 10px;
		padding-left: 10px;
		border-bottom: 0;
		background-color: #f7f7f7;
		-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.85);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0.85);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.bar-nav {
		background-color: rgba(255, 255, 255, 0);
		transition: all 0.5s;
		box-shadow: inherit;
		z-index: 100;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.bar-nav.bg-nav {
		background-color: rgba(255, 255, 255, 1);
	}

	.bar-nav .iconwarp {
		padding: 0 10px;
		height: 100%;
		display: inline-block;
	}

	.bar-nav .iconfont {
		font-size: 22px;
		padding: 5px;
		color: #000000;
	}

	.bar-nav .iconfont.white {
		font-size: 22px;
		padding: 5px;
		color: #ffffff;
	}

	.bar-nav.bg-nav .iconfont {
		color: #000000;
	}

	.bar-nav.bg-nav.bar .icon:after,
	.bar-nav.bg-nav.bar .icon:before,
	.bar-nav.bg-nav.bar .icon em {
		background-color: #000000;
	}

	.bar .icon {
		position: relative;
		width: 19px;
		height: 16px;
		display: inline-block;
		top: 14px;
	}

	.bar .icon:after,
	.bar .icon:before,
	.bar .icon em {
		content: '';
		display: inline-block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #000;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}

	.bar .icon.white:after,
	.bar .icon.white:before,
	.bar .icon.white em {
		content: '';
		display: inline-block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #ffffff;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}

	.bar .icon:before {
		top: 0;
	}

	.bar .icon:after {
		bottom: 0;
	}

	.bar .icon em {
		top: 50%;
		margin-top: -1px;
	}

	.show-bar-nav.bar-nav.bar .icon:before {
		-webkit-transform: matrix(0.70711, 0.70711, -0.70711, 0.70711, 0, 9);
		-ms-transform: matrix(0.70711, 0.70711, -0.70711, 0.70711, 0, 9);
		transform: matrix(0.70711, 0.70711, -0.70711, 0.70711, 0, 9);
		background-color: #1a1a1a;
	}

	.show-bar-nav.bar-nav.bar .icon:after {
		-webkit-transform: matrix(0.70711, -0.70711, 0.70711, 0.70711, 0, -6);
		-ms-transform: matrix(0.70711, -0.70711, 0.70711, 0.70711, 0, -6);
		transform: matrix(0.70711, -0.70711, 0.70711, 0.70711, 0, -6);
		background-color: #1a1a1a;
	}

	.show-bar-nav.bar-nav.bar .icon em {
		opacity: 0;
	}

	.nav {
		position: fixed;
		top: 0;
		padding-top: 44px;
		left: 0;
		width: 100%;
		z-index: 10;
		background-color: rgba(255, 255, 255, 0);
		height: 0;
		overflow: hidden;
		visibility: hidden;
		font-size: 16px;
		color: #333;
		transition: all 0.4s;
	}

	.show-nav {
		height: 100vh;
		background-color: rgba(255, 255, 255, 1);
		visibility: visible;
	}

	.nav .nav-list {
		padding: 0 40px 40px 40px;
		margin: 0;
		display: none;
	}

	.show-nav.nav .nav-list {
		display: block;
		margin-top: 15px;
	}

	.nav-list .nav-item {
		min-height: 50px;
		border-bottom: 1px solid #f2f2f2;
		line-height: 50px;
	}

	.nav-list .nav-item .title {
		display: flex;
		justify-content: space-between;
	}

	.nav-list .nav-item .title .icon {
		line-height: 50px;
		transition: all 0.4s;
		font-size: 20px;
		color: #c0c5c9;
	}

	.nav-list .nav-item.show-item .title .icon {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
	}

	.nav-list .nav-item .nav-item-list {
		max-height: 0;
		overflow: hidden;
		transition: all 0.4s;
		padding-left: 15px;
		visibility: hidden;
	}

	.nav-list .nav-item .nav-item-list .nav-itemitem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}

	.nav-list .nav-item .nav-item-list .nav-itemitem:active {
		background: rgba(0, 213, 150, 0.05);
	}

	.nav-list .nav-item .nav-item-list .nav-itemitem .icon {
		color: #c0c5c9;
		font-size: 20px;
	}

	.nav-list .nav-item.show-item .nav-item-list {
		max-height: 300px;
		transition: all 0.4s;
		visibility: visible;
	}
</style>
